import React from 'react'
import Helmet from 'react-helmet'
import _ from 'lodash'

import { FaChevronRight } from 'react-icons/fa'

import { Link, graphql } from 'gatsby'

import './style.scss'
import Layout from '../layouts'
import { departmentPath, regionId, regionPath } from '../path'


const regionList = (data) => {
  const duplicatesRegions = _.map(_.map(
    data.allCitiesJson.edges, 'node',
  ), 'region')

  return _.uniqBy(duplicatesRegions, 'code')
}

const departmentInRegion = (data, region) => {
  const duplicatesDepartments = _.map(_.filter(_.map(
    data.allCitiesJson.edges, 'node',
  ), (node) => node.region.code === region.code), 'department')

  return _.uniqBy(duplicatesDepartments, 'code')
}

const RegionList = ({ data }) => (
  <Layout>
    <section className="section">
      <div className="container">
        <Helmet title="Les meilleurs bar de France" />
        <h1 className="title">
          Les meilleurs bars de France
        </h1>
        <div className="content">
          <p>
            Trouvez les meilleurs bar de France avec MisterGoodBeer.
            Tous les bars les moins chers, avec les meilleures happy hours et
            les meilleures bières y sont référencés.
          </p>
          <h2>Choisissez votre région</h2>
          <ul>
            {regionList(data)
              .map((region) => (
                <li key={region.code}>
                  <Link to={regionPath(region)}>
                    {region.name}
                    <span className="icon is-large">
                      <FaChevronRight />
                    </span>
                  </Link>
                </li>
              ))}
          </ul>

          <ul>
            {regionList(data)
              .map((region) => (
                <li key={region.name}>
                  <h2 id={regionId(region)}>
                    {`${region.name} : les meilleurs bars`}
                  </h2>
                  <ul>
                    {departmentInRegion(data, region).map((department) => (
                      <li>
                        <Link to={departmentPath(department)}>
                          {`${department.code} - ${department.name}`}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  </Layout>
)

export default RegionList


export const pageQuery = graphql`
  query RegionsAndDepartments {
    allCitiesJson {
      edges {
        node {
          name
          department {
            code
            name
          }
          region {
            code
            name
          }
        }
      }
    }
  }
`
